import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import {
  FAILED_STATUS,
  NO_STATUS,
  PENDING_STATUS,
  SUCCESS_STATUS,
} from "../utils/StatusManage";

const OrderSummary = ({ setShowOrder, orderSummary }) => {
  return (
    <Card>
      <CardHeader>Order Summary</CardHeader>
      <CardContent>
        <Grid
          style={{ padding: 25 }}
          spacing={6}
          justifyContent="space-around"
          container
        >
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h5"> All </Typography>
            <Button onClick={() => setShowOrder(NO_STATUS)}>
              <Typography variant="h1">
                {orderSummary
                  ? +(
                      orderSummary?.completed +
                      orderSummary?.pending +
                      orderSummary?.failed
                    )
                  : ""}
              </Typography>
            </Button>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h5"> Completed </Typography>
            <Button
              style={{ color: "green" }}
              onClick={() => setShowOrder(SUCCESS_STATUS)}
            >
              <Typography variant="h1">
                {orderSummary?.completed && +orderSummary?.completed}
              </Typography>
            </Button>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h5"> Pending </Typography>
            <Button
              style={{ color: "orange" }}
              onClick={() => setShowOrder(PENDING_STATUS)}
            >
              <Typography variant="h1">
                {orderSummary?.pending && +orderSummary?.pending}
              </Typography>
            </Button>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="h5"> Failed </Typography>
            <Button
              style={{ color: "red" }}
              onClick={() => setShowOrder(FAILED_STATUS)}
            >
              <Typography variant="h1">
                {orderSummary?.failed && +orderSummary?.failed}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderSummary;
