import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Loading from "./Loading";
import OrderXMLFormat from "./OrderXMLFormat";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {index === 1 ? children : <code>{children}</code>}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const OrderTabs = ({ selectedOrderId, selectedOrderStatus, handleClose,salesOrderId,axOrderId, isHandled }) => {
  const theme = useTheme();

  const getDefaultSelectedValue = (item) => {
    if (item?.orderedi) return 0;
    if (item?.orderxml) return 1;
    if (item?.orderxmlax) return 2;
    if (item?.error) return 3;
  };
  const [loader, setLoader] = React.useState(false);
  const [filesData, setFilesData] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getFilesData = async (item) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("apikey", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}/order/fileData/${item}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          setFilesData(result.files);
          setValue(getDefaultSelectedValue(result.files));
        }
        setLoader(false);
      })
      .catch((error) => setLoader(false));
  };
  React.useEffect(() => {
    if (selectedOrderId) getFilesData(selectedOrderId);
  }, [selectedOrderId]);

  return (
    <>
      <Grid sx={{ bgcolor: "background.paper", minHeight: 800 }}>
        <AppBar position="sticky" style={{ overflowX: "hidden" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              disabled={!filesData?.orderedi}
              label="Order EDI"
              {...a11yProps(0)}
            />
            <Tab
              disabled={!filesData?.orderxml}
              label="Order XML"
              {...a11yProps(1)}
            />
            <Tab
              disabled={!filesData?.orderxmlax}
              label="Order XML AX"
              {...a11yProps(2)}
            />
            <Tab
              disabled={!(filesData?.successResponse || filesData?.error)}
              label="Order Status"
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <div style={{ whiteSpace: "break-spaces", lineHeight: 1.5 }}>
            {filesData?.orderedi || ""}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <OrderXMLFormat
            handleClose={handleClose}
            orderRowId={selectedOrderId}
            selectedOrderStatus={selectedOrderStatus}
            xml={filesData?.orderxml || ""}
            salesOrderId={salesOrderId}
            axOrderId={axOrderId}
            isHandled={isHandled}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div style={{ whiteSpace: "break-spaces", lineHeight: 1.5 }}>
            {filesData?.orderxmlax || ""}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div style={{ whiteSpace: "break-spaces", lineHeight: 1.5 }}>
            {(filesData?.successResponse || filesData?.error) || ""}
          </div>
        </TabPanel>

        {loader ? <Loading /> : <></>}
      </Grid>
    </>
  );
};

export default OrderTabs;
