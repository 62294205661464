import { LoadingButton } from "@mui/lab";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  IconButton,
  Popover,
  Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { convert } from "xmlbuilder2";
import { SUCCESS_STATUS, PENDING_STATUS } from "../utils/StatusManage";
import moment from 'moment';
import Loading from "./Loading";
import CopyToClipboard from "react-copy-to-clipboard";
// import LoadingButton from "@mui/lab/LoadingButton";
const OrderRow = (props) => {
  const { item, index, updateLine, deleteLine, CustomerAccount, companyId = 61 } = props
  const [disabled, setDisabled] = useState(false)
  const onPriceUpdateFunction = () => {
    setDisabled(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );

    var raw = JSON.stringify({
      productId: item.ItemId,
      salesUnit: item.SalesUnit,
      quantity: item.SalesQuantity,
      calcDate: item.CalculationDate
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}/order/price/${CustomerAccount}/${companyId}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status === 200) {
          const data = result.data;
          let updatedItem = {
            ...item,
            LinePrice: data.linePrice,
            SalesPrice: data.salesPrice,
            SrpPerOneUnit: data.srpPerOneUnit,
            Discount1: data.discount1 || 0,
            Discount2: data.discount2 || 0
          }
          updateLine(updatedItem, index)
        }
        setDisabled(false)
      })
      .catch((error) => {
        console.log('Error')
        setDisabled(false)
      });
  }
  return (
    <TableRow key={`${item.index}_${item.VehicleRegisterNumber}`}>
      <TableCell sx={{ minWidth: "90px" }}>
        <TextField
          size="small"
          id="SalesQuantity"
          value={item.ItemId}
          fullWidth
          onChange={(e) =>
            updateLine({ ...item, ItemId: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>{item.VehicleRegisterNumber}</TableCell>
      <TableCell>
        <TextField
          size="small"
          id="SalesQuantity"
          value={item.SalesQuantity}
          onChange={(e) =>
            updateLine({ ...item, SalesQuantity: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="LinePrice"
          value={item.LinePrice}
          onChange={(e) =>
            updateLine({ ...item, LinePrice: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="SalesPrice"
          value={item.SalesPrice}
          onChange={(e) =>
            updateLine({ ...item, SalesPrice: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="SrpPerOneUnit"
          value={item.SrpPerOneUnit}
          onChange={(e) =>
            updateLine({ ...item, SrpPerOneUnit: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="Discount1"
          value={item.Discount1}
          onChange={(e) =>
            updateLine({ ...item, Discount1: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="Discount2"
          value={item.Discount2}
          onChange={(e) =>
            updateLine({ ...item, Discount2: e.target.value }, index)
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="InventorySiteId"
          value={item.InventorySiteId}
          onChange={(e) =>
            updateLine(
              { ...item, InventorySiteId: e.target.value },
              index
            )
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          id="InventoryLocationId"
          value={item.InventoryLocationId}
          onChange={(e) =>
            updateLine(
              { ...item, InventoryLocationId: e.target.value },
              index
            )
          }
          variant="outlined"
          disabled={disabled}
        />
      </TableCell>
      {/* Action Buttons */}
      <TableCell>
        <div style={{ display: "flex" }}>
          <IconButton size="small" color="info" title="Refresh Price" onClick={() => {
            console.log(item, index)
            onPriceUpdateFunction()
          }} disabled={disabled}>
            <Icon>
              <i className="fa-solid fa-md fa-refresh"></i>
            </Icon>
          </IconButton>
          <IconButton
            onClick={() => deleteLine(index)}
            size="small"
            color="error"
            disabled={disabled}
          >
            <Icon>
              <i className="fa-solid fa-lg fa-xmark"></i>
            </Icon>
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}
const OrderLines = ({ orderLine, updateLine, deleteLine, CustomerAccount, companyId = 61 }) => {

  return (
    <Table sx={{ minWidth: 600, marginY: 5 }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>ItemId</TableCell>
          <TableCell>VehicleRegisterNumber</TableCell>
          <TableCell>SalesQuantity</TableCell>
          <TableCell>LinePrice</TableCell>
          <TableCell>SalesPrice</TableCell>
          <TableCell>SrpPerOneUnit</TableCell>
          <TableCell>Discount1</TableCell>
          <TableCell>Discount2</TableCell>
          <TableCell>InventorySiteId</TableCell>
          <TableCell>InventoryLocationId</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderLine.map((item, index) => (
          <OrderRow
            item={item} index={index} updateLine={updateLine} deleteLine={deleteLine} CustomerAccount={CustomerAccount} companyId='61'
          />
        ))}
      </TableBody>
    </Table>
  );
};

function parseToJSON(xml) {
  try {
    let obj = convert(xml, { format: "object" })?.SalesOrder;
    function removeEmpty(obj) {
      if (typeof obj === "string") {
        return obj;
      } else if (typeof obj === "object" && JSON.stringify(obj) === "{}") {
        return "";
      } else {
        if (Array.isArray(obj)) {
          return obj.map((item) => removeEmpty(item));
        }
        let newObj = {};
        Object.keys(obj).forEach((item) => {
          newObj[item] = removeEmpty(obj[item]);
        });
        return newObj;
      }
    }

    obj = removeEmpty(obj);
    return obj;
  } catch (e) {
    return xml;
  }
}

function parseToXML(json) {
  try {
    function stringToEmptyObject(obj) {
      if (typeof obj === "object") {
        if (Array.isArray(obj)) {
          let newArr = [];
          obj.forEach((item) => {
            newArr.push(stringToEmptyObject(item));
          });
          return newArr;
        }
        let newObj = {};
        Object.keys(obj).forEach((item) => {
          newObj[item] = stringToEmptyObject(obj[item]);
        });
        return newObj;
      } else if (obj === "") return {};
      else return obj;
    }

    let SalesOrder = stringToEmptyObject(json);
    let xml = convert({ SalesOrder }, { prettyPrint: true });
    xml = xml.replace(
      '<?xml version="1.0"?>',
      '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>'
    );
    return xml;
  } catch (e) {
    return "Failed to parse";
  }
}

const OrderXMLFormat = ({
  xml,
  selectedOrderStatus,
  orderRowId,
  handleClose,
  salesOrderId,
  axOrderId,
  isHandled
}) => {
  const [editXML, setEditXML] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [newXML, setNewXML] = useState(xml);
  const [parsed, setParsed] = useState(parseToJSON(newXML));
  const [copied, setCopied] = useState({
    label: null,
    isSelected: false,
  });

  const copyHandler = (label) => {
    setCopied(() => {
      return { ...copied, label: label, isSelected: true };
    });
  };

  const updateLine = (orderLine, index) => {
    let newObj = JSON.parse(JSON.stringify(parsed));
    if (newObj?.OrderLines?.OrderLine[0]) {
      newObj.OrderLines.OrderLine[index] = { ...orderLine };
    } else {
      newObj.OrderLines.OrderLine = { ...orderLine };
    }
    setParsed(newObj);
  };

  const deleteLine = (index) => {
    let newObj = JSON.parse(JSON.stringify(parsed));
    if (newObj?.OrderLines?.OrderLine[0]) {
      if (newObj?.OrderLines?.OrderLine.length === 1)
        newObj.OrderLines.OrderLine = newObj.OrderLines.OrderLine[0];
      else newObj.OrderLines.OrderLine.splice(index, 1);
    }
    setParsed(newObj);
  };

  const updateXML = (json) => {
    setNewXML(parseToXML(json));
    setEditXML(false);
  };

  function submitOrder(xml) {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/xml");
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: xml,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/integration/order/reprocess?orderRowId=${orderRowId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);

        if (result.status === 200) {
          toast.success("Order Reprocessed successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          handleClose();
        } else
          toast.error("Error while reprocessing!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
      })
      .catch((error) => setLoader(false));
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickConfirmation = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseConfirmation = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
     
      {editXML ? (
        <>
          <Table sx={{ minWidth: 650, marginBottom: 5 }} size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head">CustomerAccount</TableCell>
                <TableCell>{parsed.CustomerAccount}</TableCell>
                <TableCell variant="head">DeliveryPostalAddress</TableCell>
                <TableCell>{parsed.DeliveryPostalAddress}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Street</TableCell>
                <TableCell>{parsed.Street}</TableCell>
                <TableCell variant="head">ZipCode</TableCell>
                <TableCell>{parsed.ZipCode}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table sx={{ minWidth: 650, marginY: 5 }} size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head">Orderer</TableCell>
                <TableCell>{parsed.Orderer}</TableCell>
                <TableCell variant="head">InvoiceAccount</TableCell>
                <TableCell>{parsed.InvoiceAccount}</TableCell>
                <TableCell variant="head">ReceiptDateRequested</TableCell>
                <TableCell>{parsed.ReceiptDateRequested}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Description</TableCell>
                <TableCell>{parsed.Description}</TableCell>
                <TableCell variant="head">SalesOrigin</TableCell>
                <TableCell>{parsed.SalesOrigin}</TableCell>
                <TableCell variant="head">
                  VehicleRegisterNumberHeader
                </TableCell>
                <TableCell>{parsed.VehicleRegisterNumberHeader}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head">InventorySiteId</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    id="inventory-site-id"
                    value={parsed.InventorySiteId}
                    onChange={(e) =>
                      setParsed({ ...parsed, InventorySiteId: e.target.value })
                    }
                    variant="outlined"
                  />
                </TableCell>
                <TableCell variant="head">InventoryLocationId</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    id="inventory-location-id"
                    value={parsed.InventoryLocationId}
                    onChange={(e) =>
                      setParsed({
                        ...parsed,
                        InventoryLocationId: e.target.value,
                      })
                    }
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">DeliveryMode</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    id="delivery-mode"
                    value={parsed.DeliveryMode}
                    onChange={(e) =>
                      setParsed({
                        ...parsed,
                        DeliveryMode: e.target.value,
                      })
                    }
                    variant="outlined"
                  />
                </TableCell>
                <TableCell variant="head">DeliveryTerm</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    id="delivery-term"
                    value={parsed.DeliveryTerm}
                    onChange={(e) =>
                      setParsed({
                        ...parsed,
                        DeliveryTerm: e.target.value,
                      })
                    }
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {parsed?.OrderLines?.OrderLine ? (
            parsed?.OrderLines?.OrderLine?.[0] ? (
              <OrderLines
                orderLine={Object.values(parsed.OrderLines.OrderLine)}
                updateLine={updateLine}
                deleteLine={deleteLine}
                CustomerAccount={parsed.CustomerAccount}
              />
            ) : (
              <OrderLines
                orderLine={[parsed?.OrderLines?.OrderLine]}
                updateLine={updateLine}
                deleteLine={deleteLine}
                CustomerAccount={parsed.CustomerAccount}
              />
            )
          ) : (
            <></>
          )}
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => setEditXML(false)}
              variant="contained"
              color="error"
              style={{ float: "right" }}
            >
              Close
            </Button>
            <Button
              onClick={() => updateXML(parsed)}
              variant="contained"
              style={{ float: "right", marginRight: 15 }}
            >
              Update XML
            </Button>
          </div>
        </>
      ) : (
        <>
          <Table sx={{ minWidth: 650, marginBottom: 5 }} size="small">
            <TableBody>
              <TableRow>
                <TableCell variant="head">OrderId</TableCell>
                <TableCell>
                  {salesOrderId ? salesOrderId : ""}
                  {salesOrderId ? (
                    <button
                      type="button"
                      style={{
                        border: "none",
                        cursor: "pointer",

                        backgroundColor: "white",
                      }}
                      data-clipboard-action="copy"
                      data-clipboard-target="#vin"
                    >
                      <span>
                        {copied?.label === "orderId" ? (
                          <i class="fa-solid fa-check" style={{ color: "green" }} ></i>
                        ) : (
                          <CopyToClipboard
                            text={salesOrderId}
                            onCopy={() => copyHandler("orderId")}
                          >
                            <i class="fa-solid fa-copy"></i>
                          </CopyToClipboard>
                        )}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell variant="head">AXOrder Id</TableCell>
                <TableCell>
                  {axOrderId}{" "}
                  {axOrderId ? (
                    <button
                      type="button"
                      style={{
                        border: "none",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      data-clipboard-action="copy"
                      data-clipboard-target="#vin"
                    >
                      <span>
                        {copied?.label === "axOrderId" ? (
                          <i class="fa-solid fa-check" style={{ color: "green" }} ></i>
                        ) : (
                          <CopyToClipboard
                            text={axOrderId}
                            onCopy={() => copyHandler("axOrderId")}
                          >
                            <i class="fa-solid fa-copy"></i>
                          </CopyToClipboard>
                        )}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell variant="head">CustomerAccount</TableCell>
                <TableCell>
                  {parsed?.CustomerAccount}
                  {parsed?.CustomerAccount ? (
                    <button
                      type="button"
                      style={{
                        border: "none",
                        cursor: "pointer",

                        backgroundColor: "white",
                      }}
                      data-clipboard-action="copy"
                      data-clipboard-target="#vin"
                    >
                      <span>
                        {copied?.label === "customerAccount" ? (
                          <i class="fa-solid fa-check" style={{ color: "green" }} ></i>
                        ) : (
                          <CopyToClipboard
                            text={parsed?.CustomerAccount}
                            onCopy={() => copyHandler("customerAccount")}
                          >
                            <i class="fa-solid fa-copy"></i>
                          </CopyToClipboard>
                        )}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">SalesOrigin</TableCell>
                <TableCell>{parsed?.SalesOrigin?.toUpperCase()}</TableCell>
                <TableCell variant="head">SalesOrderTarget</TableCell>
                <TableCell>{parsed?.SalesOrderTarget?.toUpperCase()}</TableCell>
                <TableCell variant="head">ReceiptDateRequested</TableCell>
                <TableCell>{moment.utc(parsed?.ReceiptDateRequested).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div style={{ position: "sticky", width: "90%" }}>
            {selectedOrderStatus !== SUCCESS_STATUS && !editXML ? (
              <>
                <LoadingButton
                  variant="contained"
                  // onClick={() => submitOrder(newXML)}
                  onClick={handleClickConfirmation}
                  style={{ float: "right", marginLeft: 15 }}
                  loading={loader}
                  loadingPosition="end"
                  endIcon={
                    <i class="fa-solid fa-floppy-disk-circle-arrow-right"></i>
                  }
                  disabled={isHandled || selectedOrderStatus === PENDING_STATUS}
                >
                  Submit Order
                </LoadingButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseConfirmation}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    style: { width: 200, padding: 10, marginTop: 10 },
                  }}
                  elevation={20}
                >
                  <center>
                    <Typography>
                      Submit the order?
                    </Typography>
                    <LoadingButton
                      variant="contained"
                      onClick={(e) => {submitOrder(newXML);handleCloseConfirmation(e)}}
                      // onClick={handleClickConfirmation}
                      size='small'
                      loading={loader}
                      loadingPosition="end"
                    >
                      Confirm
                    </LoadingButton>
                  </center>
                </Popover>

                <Button
                  onClick={() => setEditXML(!editXML)}
                  variant="contained"
                  style={{ float: "right" }}
                  disabled={isHandled || selectedOrderStatus === PENDING_STATUS}
                >
                  Edit XML
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>

          <div style={{ whiteSpace: "break-spaces", lineHeight: 1.5 }}>
            {newXML}
          </div>
        </>
      )}
    </>
  );
};

export default OrderXMLFormat;
