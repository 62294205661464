import {
  AppBar,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  FormControlLabel
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import OrderDialog from "../components/OrderDialog";
import OrderSummary from "../components/OrderSummary";
import moment from "moment";
import OrderTabs from "../components/OrderTabs";
import { SocketContext } from "../context/socket";
import {
  FAILED,
  FAILED_STATUS,
  filterStatus,
  getStatus,
  NO_STATUS,
  PENDING_STATUS,
  SUCCESS_STATUS,
} from "../utils/StatusManage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AuthContext } from "../context/AuthContext";

const FILTER_BY_DATE = -1;
const FILTER_BY_ORDER_ORIGIN = -2;

const getStatusText = (params, variant = "p") => {
  return (
    <Typography
      variant={variant}
      style={
        getStatus(params.row.status) === SUCCESS_STATUS
          ? { color: "green", textAlign: "center" }
          : getStatus(params.row.status) === FAILED_STATUS
            ? { color: "red", textAlign: "center" }
            : { color: "orange", textAlign: "center" }
      }
    >
      {getStatus(params.row.status) === SUCCESS_STATUS
        ? "Success"
        : getStatus(params.row.status) === FAILED_STATUS
          ? "Failed"
          : "Pending"}
    </Typography>
  );
};

const pageSize = 5;

const orderOriginFields = [
  { value: -1, text: "All" },
  { value: "futursoft", text: "Futursoft" },
  { value: "orumnet", text: "Orumnet" },
  { value: "orumnet3.0", text: "Orumnet 3.0" },
  { value: "sas", text: "SAS" }
];

const OrdersStatus = () => {
  const [orderStatus, setOrderStatus] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const socket = useContext(SocketContext);
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const initialFilter = {
    orderId: "",
    axOrderId: "",
    showOrder: NO_STATUS,
    customerId: "",
    from: moment(),
    to: moment(),
    pastRecordsDay: 7,
    orderOrigin: -1,
    sort_order: 'desc',
    sort_field: 'order_created'
  };
  const [filter, setFilter] = useState({ ...initialFilter });
  const [defaultFilter, setDefaultFilter] = useState({ ...initialFilter });
  const refFilter = useRef(initialFilter);
  const refAutoRefresh = useRef(true)
  const [autoRefresh, setAutoRefresh] = useState(true)

  useEffect(() => {
    refFilter.current = filter;
  }, [filter]);

  useEffect(() => {
    refAutoRefresh.current = autoRefresh;
  }, [autoRefresh]);

  const filterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    sessionStorage.setItem("page", page);
  }, [page]);

  useEffect(() => {
    try {
      fetchOrders();
      socket.on("connect", (data) => console.log("connect", data));
      socket.on("update", (data) => console.log(data));
      socket.on("connect_error", (err) => console.log(err));
      socket.on("connect_failed", (err) => console.log(err));
      socket.on("disconnect", (err) => console.log(err));
      socket.on("orderUpdate", () => {
        // if autorefresh is on then only  else stop calling api
        if (refAutoRefresh.current) {
          fetchOrders(
            sessionStorage.getItem("page") + 1,
            undefined,
            refFilter.current,
            true
          );
        }
      });
    } catch (e) {
      console.log("err", e);
    }
    return () => socket.disconnect();
  }, []);

  const fetchOrders = async (
    draw = 1,
    limit = pageSize,
    filtersValues = { ...defaultFilter },
    autoUpdate
  ) => {
    draw = autoUpdate ? +sessionStorage.getItem("page") + 1 : draw;
    setLoading(true);
    let status = "All";
    if (filtersValues.showOrder === SUCCESS_STATUS) status = "completed";
    else if (filtersValues.showOrder === FAILED_STATUS) status = "failed";
    else if (filtersValues.showOrder === PENDING_STATUS) status = "pending";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );

    var raw = JSON.stringify({
      draw,
      limit,
      status,
      interval:
        filtersValues.pastRecordsDay !== FILTER_BY_DATE &&
          filtersValues.pastRecordsDay !== 1 &&
          filtersValues.pastRecordsDay !== FILTER_BY_ORDER_ORIGIN
          ? filtersValues.pastRecordsDay
          : undefined,
      orderId: filtersValues.orderId.trim(),
      axOrderId: filtersValues.axOrderId.trim(),
      from:
        filtersValues.pastRecordsDay === FILTER_BY_DATE
          ? moment(filtersValues.from || filtersValues.to).format("YYYY-MM-DD")
          : filtersValues.pastRecordsDay === 1
            ? moment().subtract(1, "days").format("YYYY-MM-DD")
            : undefined,
      to:
        filtersValues.pastRecordsDay === FILTER_BY_DATE
          ? moment(filtersValues.to || filtersValues.from).format("YYYY-MM-DD")
          : filtersValues.pastRecordsDay === 1
            ? moment().subtract(1, "days").format("YYYY-MM-DD")
            : undefined,
      customerId: filtersValues.customerId.trim(),
      orderOrigin:
        filtersValues.orderOrigin === -1
          ? undefined
          : filtersValues.orderOrigin === 'orumnet3.0' ? 'orumnet' : filtersValues.orderOrigin,
      sort_order: filtersValues.sort_order,
      sort_field: filtersValues.sort_field,
      app_version: filtersValues.orderOrigin === 'orumnet3.0' ? 3 : undefined
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(`${process.env.REACT_APP_API_URL}/order`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status === 200)
          setOrderStatus(result.data.orders.map((item) => item));
        setPageInfo({ total: result.data.total });
        setLoading(false);
        let { completed, pending, failed, total: all } = result.data;
        setOrderSummary({ completed, pending, failed, all });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const markAsHandelButton = (id, isHandled) => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_URL}/order/mark/handled?orderId=${id}&handled=${isHandled}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 200) {
          fetchOrders(page + 1)
        } else {
          setLoading(false)
        }
      })
      .catch(error => { console.log('error', error); setLoading(false) });
    // fetchOrders(page + 1)
    // setLoading(false)
  }

  const [selectedOrder, setSelectedOrder] = useState(null);

  const gridColumns = [
    {
      field: "sales_order_id",
      headerName: "Order id",
      flex: 0.16,
      sortable: false,
    },
    {
      field: "ax_order_id",
      headerName: "AXOrder id",
      flex: 0.16,
      sortable: false,
    },
    {
      field: "customerid",
      headerName: "Customer id",
      flex: 0.16,
      sortable: false,
    },
    {
      field: "order_origin",
      headerName: "Order origin",
      flex: 0.16,
      sortable: false,
    },
    {
      field: "ordercreated",
      headerName: "Created date",
      valueGetter: (params) =>
        moment.utc(params.row.ordercreated).local().format("DD.MM.YYYY HH:mm:ss"),
      flex: 0.16,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.16,
      sortable: false,
      renderCell: getStatusText,
    },
    {
      field: "button",
      headerName: "",
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            style={{ textTransform: "none", fontWeight: "bold" }}
            onClick={() => {
              setSelectedOrder(params.row);
            }}
          >
            i
          </Button>
          {
            FAILED.includes(params.row.status) ? (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Checkbox
                  checked={params.row.is_handled}
                  title={params.row.is_handled ? "Mark as Failed" : "Mark as Handled"}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                  onChange={() => markAsHandelButton(params.row.id, 1 - params.row.is_handled)}
                // style={{marginLeft: 10}}
                />
              </div>
            ) : null
          }
        </>
      ),
      sortable: false,
      flex: 0.16,
    },
  ];

  const ChangePage = (newPage) => {
    fetchOrders(newPage + 1, pageSize);
    setPage(newPage);
  };

  const [rowCountState, setRowCountState] = useState(pageInfo?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.total !== undefined ? pageInfo?.total : prevRowCountState
    );
  }, [pageInfo?.total, setRowCountState]);


  return (
    <Grid container justifyContent="center">
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <AppBar variant="elevation">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Order Status
            </Typography>
            <Button onClick={logout} color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid item xs={10} style={{ padding: 45, fontWeight: "bold" }}>
        <Grid item style={{ padding: 15 }}>
          <OrderSummary
            orderSummary={orderSummary}
            setShowOrder={(order) => {
              setPage(0);
              setFilter({ ...defaultFilter, showOrder: order });
              setDefaultFilter({ ...defaultFilter, showOrder: order });
              fetchOrders(1, pageSize, {
                ...defaultFilter,
                showOrder: order,
              });
            }}
          />
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          columnSpacing={1}
          columns={14}
          sx={{ paddingY: 2 }}
        >
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              value={filter.orderId}
              name="orderId"
              id="order-id"
              label="Order id"
              variant="outlined"
              onChange={filterChange}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              value={filter.axOrderId}
              name="axOrderId"
              id="axorder-id"
              label="AXOrder id"
              variant="outlined"
              onChange={filterChange}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              value={filter.customerId}
              name="customerId"
              id="customer-id"
              label="Customer id"
              variant="outlined"
              onChange={filterChange}
            />
          </Grid>
          <Grid xs={2} item alignSelf="center">
            <FormControl fullWidth>
              <InputLabel id="days-label">Order Origin</InputLabel>
              <Select
                id="orderOrigin"
                value={filter.orderOrigin}
                name="orderOrigin"
                size="small"
                label="Order Origin"
                onChange={filterChange}
              >
                {
                  orderOriginFields.map(field => (
                    <MenuItem id={field.value} value={field.value}>{field.text}</MenuItem>
                  ))
                }
                {/* <MenuItem value={-1}>All</MenuItem>
                <MenuItem value="futursoft">Futursoft</MenuItem>
                <MenuItem value="orumnet">Orumnet</MenuItem>
                <MenuItem value="orumnet3.0">Orumnet 3.0</MenuItem>
                <MenuItem value="sas">SAS</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="days-label">Records</InputLabel>
              <Select
                id="days"
                value={filter.pastRecordsDay}
                name="pastRecordsDay"
                size="small"
                label="records"
                onChange={filterChange}
              >
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>Yesterday</MenuItem>
                <MenuItem value={7}>Past 7 days</MenuItem>
                <MenuItem value={30}>Past 30 days</MenuItem>
                <MenuItem value={FILTER_BY_DATE}>Filter by Date Range</MenuItem>
                {/* <MenuItem value={FILTER_BY_ORDER_ORIGIN}>
                  Filter by Order Origin
                </MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Grid container columns={13} justifyContent="space-between">
              {filter.pastRecordsDay === FILTER_BY_DATE ? (
                <>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="from"
                        value={filter.from}
                        disableFuture
                        onChange={(newValue) => {
                          setFilter({
                            ...filter,
                            from: moment(new Date(newValue)),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="to"
                        value={filter.to}
                        disableFuture
                        minDate={filter.from || moment()}
                        onChange={(newValue) => {
                          setFilter({
                            ...filter,
                            to: moment(new Date(newValue)),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {filter.pastRecordsDay === FILTER_BY_ORDER_ORIGIN ? (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="days-label">Order Origin</InputLabel>
                    <Select
                      id="orderOrigin"
                      value={filter.orderOrigin}
                      name="orderOrigin"
                      size="small"
                      label="Order Origin"
                      onChange={filterChange}
                    >
                      <MenuItem value={-1}>All</MenuItem>
                      <MenuItem value="futursoft">Futursoft</MenuItem>
                      <MenuItem value="orumnet">Orumnet</MenuItem>
                      <MenuItem value="sas">SAS</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {/* <Grid xs={2} item alignSelf="center">
            <Grid container alignItems="center" justifyContent="center">
              <Grid
                container
                xs={11}
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Button
                  size="small"
                  onClick={() => {
                    setFilter({ ...filter, showOrder: NO_STATUS });
                    setPage(0);
                    setDefaultFilter({ ...filter, showOrder: NO_STATUS });
                    fetchOrders(undefined, undefined, {
                      ...filter,
                      showOrder: NO_STATUS,
                    });
                  }}
                  variant="contained"
                >
                  Search
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setPage(0);
                    setFilter({ ...initialFilter });
                    setDefaultFilter({ ...initialFilter });
                    fetchOrders(undefined, undefined, initialFilter);
                  }}
                  variant="contained"
                  color="error"
                >
                  clear
                </Button> 
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid
          container
          style={{ padding: 15, fontWeight: "bold" }}
          justifyContent="space-between"
        >
          <Grid item>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <Typography variant="h6">Orders </Typography>
              <Typography
                variant="h6"
                style={
                  filter.showOrder === SUCCESS_STATUS
                    ? { color: "green", paddingLeft: 10 }
                    : filter.showOrder === FAILED_STATUS
                      ? { color: "red", paddingLeft: 10 }
                      : filter.showOrder === NO_STATUS
                        ? { color: "#1976d2", paddingLeft: 10 }
                        : { color: "orange", paddingLeft: 10 }
                }
              >
                {filter.showOrder === SUCCESS_STATUS
                  ? "Success"
                  : filter.showOrder === FAILED_STATUS
                    ? "Failed"
                    : filter.showOrder === NO_STATUS
                      ? "ALL"
                      : "Pending"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {/* <Switch value={autoRefresh} /> */}
            <FormControlLabel
              value="start"
              control={<Switch color="primary" checked={autoRefresh} onChange={e => setAutoRefresh(e.target.checked)} />}
              label="Auto Refresh"
              labelPlacement="start"
              style={{ paddingRight: 10 }}
            />
            <Button
              size="small"
              onClick={() => {
                setFilter({ ...filter, showOrder: NO_STATUS });
                setPage(0);
                setDefaultFilter({ ...filter, showOrder: NO_STATUS });
                setAutoRefresh(false)
                fetchOrders(undefined, undefined, {
                  ...filter,
                  showOrder: NO_STATUS,
                });
              }}
              variant="contained"
              style={{ margin: '5px' }}
            >
              Search
            </Button>
            <Button
              size="small"
              onClick={() => {
                setPage(0);
                setFilter({ ...initialFilter });
                setDefaultFilter({ ...initialFilter });
                setAutoRefresh(true)
                fetchOrders(undefined, undefined, initialFilter);
              }}
              variant="contained"
              color="error"
              style={{ margin: '5px' }}
            >
              clear
            </Button>
            <Button onClick={() => fetchOrders(page + 1)} style={{ margin: '5px' }}>Refresh</Button>
          </Grid>
        </Grid>
        <DataGrid
          pageSize={pageSize}
          pagination
          sortingOrder={["des", "asc"]}
          initialState={{
            pagination: {
              page: 1,
              pageSize: 10,
            },
            sortModel: [
              {
                field: 'ordercreated',
                sort: 'des',
              }
            ]
          }}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          rows={orderStatus}
          columns={gridColumns}
          rowCount={rowCountState}
          loading={loading}
          page={page}
          paginationMode="server"
          onPageChange={ChangePage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(pageSize, details) => {
            fetchOrders(1, pageSize);
            setPageSize(pageSize);
            console.log("pageSize", pageSize);
          }}
          onSortModelChange={(model) => {
            setFilter({
              ...filter, ort_order: model[0].sort === 'des' ? 'des' : 'asc',
              sort_field: "order_created"
            });
            setPage(0);
            setDefaultFilter({
              ...filter, sort_order: model[0].sort === 'des' ? 'des' : 'asc',
              sort_field: "order_created"
            });
            fetchOrders(undefined, undefined, {
              ...filter,
              sort_order: model[0].sort === 'des' ? 'des' : 'asc',
              sort_field: "order_created"
            });
          }}
        />

        <OrderDialog
          open={Boolean(selectedOrder)}
          handleClose={() => setSelectedOrder(null)}
          title={selectedOrder?.sales_order_id}
          content={
            <OrderTabs
              handleClose={() => {
                setSelectedOrder(null);
                fetchOrders(1);
              }}
              salesOrderId={selectedOrder?.sales_order_id}
              axOrderId={selectedOrder?.ax_order_id}
              selectedOrderStatus={getStatus(selectedOrder?.status)}
              selectedOrderId={selectedOrder?.id}
              isHandled={selectedOrder?.is_handled}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default OrdersStatus;
