import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Icon, IconButton, Typography } from "@mui/material";

const OrderDialog = ({ title, content, open, handleClose }) => {
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth
        maxWidth="xl"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between",alignItems: "center" }}>
            <div>
                <Typography color="#1976d2" variant="h6">
                OrderId : {title}</Typography></div>
            <div style={{ alignItems: "center" }}>
              <IconButton sx={{color:"#1976d2"}} key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                <Icon > <i className="fa-solid fa-lg fa-xmark"></i></Icon>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: "0px 0px" }} dividers>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderDialog;
