import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
const Loading = ({ open, fullScreen = false }) => {
  return fullScreen ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={Boolean(open)}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  ) : (
    <Grid container justifyContent="center">
      <CircularProgress color="primary" />
    </Grid>
  );
};

export default Loading;
